import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, Injector } from '@angular/core';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { NotifierModule } from 'angular-notifier';
// import { alertNotificationConfig } from './shared/_utils';
import localeEt from '@angular/common/locales/et';
registerLocaleData(localeEt);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule, HttpLoaderFactory } from './shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';

import { MyStuffModule } from './my-stuff/my-stuff.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { AppInjector } from './app.injector.service';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';

// this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AuthorityModule } from './authority/authority.module';
import { HomeModule } from './home/home.module';
import { Router } from '@angular/router';
import { HomePageModule } from "./home-page/home-page.module";
import { MainAlertComponent } from "./shared/_common/main-alert/main-alert.component";

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    // FormsModule,
    // ReactiveFormsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ModalModule.forRoot(),
    // NotifierModule.withConfig(alertNotificationConfig),
    ToastrModule.forRoot(), // ToastrModule added
    MyStuffModule,
    DashboardModule,
    AuthorityModule,
    HomeModule,
    HomePageModule
  ],
  exports: [],
  providers: [
    { provide: LOCALE_ID, useValue: 'et' },
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    injector: Injector,
    private readonly router: Router) {
    // Store module's injector in the AppInjector class
    AppInjector.setInjector(injector);
  }
}
