<div
  id="mainMenuContainedId"
  role="navigation"
  class="menu-bg-1 container-fluid pl-0 d-flex"
>
  <div
    id="projectTitleId"
    class="navbar-brand"
  >{{ 'LIS.TITLE' | translate }}</div>
  <!-- UUS NAVBAR ################ ------------------------------------------------------------ -->
  <nav
    #menu
    id="menu-lower"
    style="width: calc(100% - 350px);"
    class="navbar-collapse navbar-expand-lg"
  >
    <!-- container -->
    <!-- <button
      class="navbar-toggler"
      type="button"
      dark
      [icon]="'menu'"
      [style]="'square'"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
    ></button> -->
    <div
      id="mainNavbarId"
      class="collapse "
      [ngClass]="{ show: !isCollapsed }"
    >
      <!-- [collapse]="isCollapsed" navbar-collapse -->
      <!-- <a class="navbar-brand" >Hidden brand</a> -->
      <div id="mainNavbarUlContainerId">
        <!--  x{{user}} x -->
        <ul
          id="mainNavbarUlId"
          *ngIf="user"
          class="first ml-2 w-100 navbar-nav py-0 justify-content-start"
        >
          <!-- navbar-left -->
          <!-- Menetleja/admin -->
          <!-- y{{selectedRoleEnvinronment}} y -->
          <ng-template [ngIf]="selectedRoleEnvinronment === 'ROLE_ADMIN'">
            <!-- DASHBOARD -->
            <li
              class="desktop nav-item"
              routerLinkActive="active"
            >
              <a
                class="nav-link"
                [routerLink]="[lang + '/mk/toolaud']"
              >
                {{ 'MAINNAV.DASHBOARD' | translate }}</a>
            </li>
            <!-- SHIPS -->
            <li
              class="ships nav-item"
              routerLinkActive="active"
              *ngIf="user?.privileges?.includes('LAEV_ANDMED')"
            >
              <a
                class="nav-link"
                [routerLink]="[lang + '/mk/laevad/otsing']"
              >
                {{ 'MAINNAV.SHIPS' | translate }}</a>
            </li>
            <!-- SMALLSHIPS -->
            <li
              class="nav-item"
              routerLinkActive="active"
              *ngIf="user?.privileges?.includes('VLAEV_ANDMED')"
            >
              <!-- id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" routerLinkActive="active" -->
              <a
                class="nav-link"
                [routerLink]="[lang + '/' + currentEnv + '/vaikelaev/otsing']"
              >
                {{ 'MAINNAV.SMALLSHIPS' | translate }}
              </a>
              <!-- <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li>
                  <a class="dropdown-item" [routerLink]="[lang + '/' + currentEnv + '/vaikelaev/otsing']">{{ 'GLOBAL.TRAFFIC_REGISTRY_SEARCH' | translate
                }}</a>
                </li>
              </ul> -->
            </li>
            <li
              class="applications nav-item"
              routerLinkActive="active"
            >
              <a
                class="nav-link"
                [routerLink]="[lang + '/mk/taotlused/otsing']"
              >{{
                'MAINNAV.APPLICATION' | translate
                }}</a>
            </li>
            <!-- INSPECTIONS -->
            <li
              class="nav-item"
              routerLinkActive="active"
              *ngIf="
                (currentEnv === 'mk' && user?.privileges?.includes('YLEV_ANDMED')) ||
                user?.privileges?.includes('VLAEV_ANDMED') ||
                currentEnv === 'ik'
              "
            >
              <a
                class="nav-link "
                id="navbarDropdownMenuLink"
                [routerLink]="[lang + '/' + currentEnv + '/ylevaatused/otsing']"
              >
                {{ 'MAINNAV.INSPECTIONS' | translate }}
              </a>
              <!--<ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                 <li>
                  <a class="dropdown-item" [routerLink]="[lang + '/' + currentEnv + '/ylevaatused/otsing']">{{ 'GLOBAL.SEARCH' | translate
                }}</a>
                </li> -->
              <!--  <li>
                  <a class="dropdown-item">{{ 'MAINNAV.UPCOMING_INSPECTIONS' | translate }}</a>
                </li>
              </ul>-->
            </li>
            <!-- HALDUS -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ 'MAINNAV.ADMINISTRATION' | translate }}
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <!-- TEAVITUSED -->
                <li
                  routerLinkActive="active"
                  *ngIf="user?.activeRoleEntity === 'ROLE_1'"
                >
                  <a
                    class="dropdown-item"
                    [routerLink]="[lang + '/mk/admin/teavitused']"
                  >
                    {{ 'MAINNAV.ADMIN_NOTIFICATIONS' | translate }}
                  </a>
                </li>
                <!-- KASUTAJAD -->
                <li
                  routerLinkActive="active"
                  *ngIf="user?.activeRoleEntity === 'ROLE_1'"
                >
                  <a
                    class="dropdown-item"
                    [routerLink]="[lang + '/mk/kasutajad']"
                  >
                    {{ 'MAINNAV.KASUTAJAD' | translate }}
                  </a>
                </li>
                <!-- VOLITUSED -->
                <li
                  class="nav-item"
                  routerLinkActive="active"
                  *ngIf="
                    user?.privileges?.includes('VOLITUSED') || user?.privileges?.includes('VOLITUSTE_HALDUR')
                  "
                >
                  <a
                    class="nav-link"
                    [routerLink]="[lang + '/mk/volitused/otsing']"
                  >{{
                    'MAINNAV.AUTHORITIES' | translate
                    }}</a>
                </li>
                <!-- OBLIGATIONS -->
                <li
                  routerLinkActive="active"
                  *ngIf="
                    user?.privileges?.includes('KOHUSTISE_ANDMED') ||
                    user?.privileges?.includes('KOHUSTISE_HALDUR')
                  "
                >
                  <a
                    class="dropdown-item"
                    [routerLink]="[lang + '/mk/kohustised/otsing']"
                  >
                    {{ 'MAINNAV.OBLIGATION_SEARCH' | translate }}
                  </a>
                </li>
                <li
                  routerLinkActive="active"
                  *ngIf="user?.privileges?.includes('KOHUSTISE_HALDUR')"
                >
                  <a
                    class="dropdown-item"
                    [routerLink]="[lang + '/' + currentEnv + '/kohustised/maksete-otsing']"
                  >
                    {{ 'MAINNAV.PAYMENT_SEARCH' | translate }}
                  </a>
                </li>
                <li
                  routerLinkActive="active"
                  *ngIf="hasPrivilegeToTempEdit"
                >
                  <a
                    class="dropdown-item"
                    [routerLink]="[lang + '/' + currentEnv + '/mallid']"
                  >
                    {{ 'MAINNAV.TEMPLATE_MANAGEMENT' | translate }}
                  </a>
                </li>
                <li
                  routerLinkActive="active"
                  *ngIf="hasPrivilegeToInspectionRequirements"
                >
                  <a
                    class="dropdown-item"
                    [routerLink]="[lang + '/mk/nouded/otsing']"
                  >
                    {{ 'MAINNAV.INSPECTION_REQUIREMENTS' | translate }}
                  </a>
                </li>
                <!-- KLASSIFIKAATORID -->
                <li routerLinkActive="active">
                  <a
                    class="dropdown-item"
                    [routerLink]="[lang + '/mk/klassifikaatorid/otsing']"
                  >
                    {{ 'MAINNAV.CLASSIFCATORS' | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ng-template>
          <!-- #### Isik ###############################################################  -->
          <ng-template [ngIf]="selectedRoleEnvinronment === 'ROLE_USER'">
            <li
              class="mystuff nav-item "
              routerLinkActive="active"
            >
              <a
                class="nav-link"
                [routerLink]="[lang + '/ik/minu-asjad']"
              >
                {{ 'MAINNAV.MY_STUFF' | translate }}
              </a>
            </li>
            <li
              class="nav-item "
              routerLinkActive="active"
            >
              <a
                class="nav-link"
                [routerLink]="[lang + '/ik/laevad/otsing']"
              >
                {{ 'MAINNAV.SHIPS' | translate }}
              </a>
            </li>
            <li
              class="applications nav-item "
              routerLinkActive="active"
            >
              <a
                class="nav-link"
                [routerLink]="[lang + '/' + currentEnv + '/taotlused/minu-taotlused']"
              >
                {{ 'MAINNAV.APPLICATION' | translate }}
              </a>
            </li>
            <li
              class="inspection nav-item "
              routerLinkActive="active"
            >
              <a
                class="nav-link"
                [routerLink]="[lang + '/' + currentEnv + '/ylevaatused/otsing']"
              >
                {{ 'MAINNAV.INSPECTIONS' | translate }}
              </a>
            </li>
          </ng-template>
          <ng-template [ngIf]="user.privileges.includes('LAEV_ANDMED')">
            <!-- <li><a>LAEV_ANDMED privileeg</a></li> -->
          </ng-template>
          <ng-template [ngIf]="user.privileges.includes('ROLE_USER')">
            <!--  <li><a>ROLE_USER privileeg</a></li> -->
          </ng-template>
          <!-- ### CODE EXAMPLE FOR NAV ######################################################### -->
          <!-- <li class="nav-item active">
          <a class="nav-link">Home <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link">Link</a>
        </li> -->
          <!-- <li class="nav-item">
          <a class="nav-link disabled">Disabled</a>
        </li> -->
          <!--  <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="http://example.com" id="navbarDropdownMenuLink"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Dropdown link
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item">Action</a></li>
            <li><a class="dropdown-item">Another action</a></li>
            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle">Submenu</a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item">Submenu action</a></li>
                <li><a class="dropdown-item">Another submenu action</a></li>


                <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle">Subsubmenu</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item">Subsubmenu action</a></li>
                    <li><a class="dropdown-item">Another subsubmenu action</a></li>
                  </ul>
                </li>
                <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle">Second subsubmenu</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item">Subsubmenu action</a></li>
                    <li><a class="dropdown-item">Another subsubmenu action</a></li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li> -->
          <!--  <li class="ml-auto">
          <app-langbar></app-langbar>
        </li> -->
        </ul>
      </div>
      <!-- <form class="form-inline my-2 my-lg-0">
        <input class="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
      </form> -->
    </div>
    <div class="headerCollapseBtn ml-auto d-flex menu-bg-2">
      <div>
        <!-- <app-langbar></app-langbar> -->
      </div>
    </div>
  </nav>
  <div
    *ngIf="selectedRoleEnvinronment"
    class=""
  >
    <button
      id="mainMenuCollapseBtnId"
      custom-class=""
      type="button"
      info
      [icon]="'menu'"
      [style]="'square'"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
    ></button>
  </div>
</div>