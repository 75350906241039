<table
    [appSortableTable]="type"
    (sorted)="onSorted($event)"
    class="table table-hover table-adaptive table-cells table-sm table-md w-100"
>
    <thead class="table-head bg-white">
        <th
            sortable-column="descr"
            [tableId]="type"
        ><a>{{ 'DOC.DOCTYPE' | translate }}</a></th>
        <th
            sortable-column="issuer"
            [tableId]="type"
        ><a>{{ 'DOC.ISSUER' | translate }}</a></th>
        <th
            sortable-column="issueDate"
            [tableId]="type"
        ><a>{{ 'DOC.ISSUED' | translate }}</a></th>
        <th
            sortable-column="validTo"
            [tableId]="type"
        ><a>{{ 'DOC.VALIDTO' | translate }}</a></th>
        <th
            sortable-column="verifiedTo"
            [tableId]="type"
        ><a>{{ 'DOC.VERIFIED' | translate }}</a></th>
        <th>{{ 'GLOBAL.ACTIVITY' | translate }}</th>
    </thead>
    <tbody class="bg-white">
        <tr *ngFor="let doc of docList; index as i">
            <!-- LAB BE document lists discrepancies -->
            <td [attr.data-label]="('DOC.DOCTYPE' | translate) + ':'">
                {{ doc?.descr ? (doc?.descr[lang] ? doc?.descr[lang] : doc?.descr) : doc?.docType?.descr ?
                doc?.docType?.descr[lang] : '' }}
                <span class="label-comment">{{ doc?.docNumber }}</span>
            </td>
            <td [attr.data-label]="('DOC.ISSUER' | translate) + ':'">{{ doc?.issuer }}</td>
            <td [attr.data-label]="('DOC.ISSUED' | translate) + ':'">{{ doc?.issueDate | date: localDateFormat }}</td>
            <td [attr.data-label]="('DOC.VALIDTO' | translate) + ':'">{{ doc?.validTo | date: localDateFormat }}</td>
            <td [attr.data-label]="('DOC.VERIFIED' | translate) + ':'">{{ doc?.verifiedTo | date: localDateFormat }}
            </td>
            <td>
                <div *ngIf="(doc.docType.groupCode !== 20 || (doc.docType.groupCode === 20 && isOmandiDokud))">
                    <!-- LAB generic actions -->
                    <div
                        class="d-flex"
                        *ngIf="type !== 'apldocs' && type !== 'cert' && type !== 'smallShipDocs'"
                    >
                        <button
                            *ngIf="mode==='ro'"
                            id="{{ 'viewDocBtnId' + type + i }}"
                            type="button"
                            light
                            [style]="'small'"
                            [icon]="'eye'"
                            title="{{ 'GLOBAL.LOOK' | translate }}"
                            (click)=onViewDoc(doc.id)
                        >
                        </button>
                        <button
                            *ngIf="mode==='rw'"
                            id="{{ 'editDocBtnId' + type + i }}"
                            type="button"
                            info
                            [style]="'small'"
                            [icon]="'square-pencil2'"
                            title="{{'GLOBAL.EDIT' | translate}}"
                            (click)="onEditDoc(doc)"
                        >
                        </button>
                        <div
                            class="ml-2"
                            *ngIf="doc.fileOriginName && !doc.deleted && !doc.signatureUuid"
                        >
                            <!-- LAB a tag is around button because this is a direct link to BE method-->
                            <a [href]="'https://laevis.bpw-consulting.com:8080/api/document/download/' + doc.id">
                                <button
                                    id="{{ 'downloadDocBtnId' + type + i }}"
                                    type="button"
                                    info
                                    [style]="'small'"
                                    [icon]="'file-download'"
                                    title="{{'GLOBAL.DOWNLOAD' | translate }}"
                                >
                                </button>
                            </a>
                        </div>
                        <div
                            class="ml-2"
                            *ngIf="doc.fileOriginName && !doc.deleted && doc.signatureUuid"
                        >
                            <!-- LAB a tag is around button because this is a direct link to BE method-->
                            <a [href]="'api/document/download-signature/' + doc.id">
                                <button
                                    id="{{ 'downloadSignedDocBtnId' + type + i }}"
                                    type="button"
                                    info
                                    [style]="'small'"
                                    [icon]="'file-download'"
                                    custom-class="id-download-btn-logo"
                                    title="{{'GLOBAL.DOWNLOAD' | translate }}"
                                >
                                </button>
                            </a>
                        </div>
                        <!-- Faili digiallkirjastamise tegevusnupu ikoon kuvada juhul,
                    kui tabel kuvatakse MK keskkonnas
                    ja fail on olemas ja on kustutamata ja doc_document.signature_uuid on tühi -->
                        <div
                            class="ml-2"
                            *ngIf="env === 'mk' && doc.fileOriginName && !doc.deleted && !doc.signatureUuid"
                        >
                            <button
                                id="{{ 'signDocBtnId' + type + i }}"
                                light
                                [style]="'small'"
                                type="button"
                                custom-class="id-logo-sm-btn"
                                (click)="signDocument(doc)"
                                title="{{'GLOBAL.SIGN' | translate }}"
                                [icon]="''"
                            >
                                <span></span>
                            </button>
                        </div>
                        <div class="ml-2">
                            <button
                                *ngIf="mode==='rw'"
                                id="{{ 'deleteDocBtnId' + type + i }}"
                                type="button"
                                danger
                                [style]="'small'"
                                [icon]="'trashcan'"
                                title="{{'GLOBAL.DELETE' | translate}}"
                                (click)="confirmDelete(doc)"
                            >
                            </button>
                        </div>
                    </div><!-- END generic actions -->
                    <!-- LAB 'apldocs' actions -->
                    <div
                        class="d-flex"
                        *ngIf="type === 'apldocs' || type === 'cert' || type === 'smallShipDocs'"
                    >
                        <button
                            *ngIf="mode==='ro' && !doc.deleted"
                            id="{{ 'viewDocBtnId' + type + i }}"
                            type="button"
                            light
                            [style]="'small'"
                            [icon]="'eye'"
                            title="{{ 'GLOBAL.LOOK' | translate }}"
                            (click)=onViewDoc(doc.id)
                        >
                        </button>
                        <button
                            *ngIf="mode==='rw' && !doc.deleted
                        && ((env === 'ik' && doc.relCount === 0 && !doc.officialOnly) || (env === 'mk'))"
                            id="{{ 'editDocBtnId' + type + i }}"
                            type="button"
                            info
                            [style]="'small'"
                            [icon]="'square-pencil2'"
                            title="{{'GLOBAL.EDIT' | translate}}"
                            (click)="onEditDoc(doc)"
                        >
                        </button>
                        <div
                            class="ml-2"
                            *ngIf="doc.fileOriginName && !doc.deleted && !doc.signatureUuid"
                        >
                            <!-- LAB a tag is around button because this is a direct link to BE method-->
                            <a [href]="'api/document/download/' + doc.id">
                                <button
                                    id="{{ 'downloadDocBtnId' + type + i }}"
                                    type="button"
                                    info
                                    [style]="'small'"
                                    [icon]="'file-download'"
                                    title="{{'GLOBAL.DOWNLOAD' | translate }}"
                                >
                                </button>
                            </a>
                        </div>
                        <div
                            class="ml-2"
                            *ngIf="doc.fileOriginName && !doc.deleted && doc.signatureUuid"
                        >
                            <!-- LAB a tag is around button because this is a direct link to BE method-->
                            <a [href]="'api/document/download-signature/' + doc.id">
                                <button
                                    id="{{ 'downloadSignedDocBtnId' + type + i }}"
                                    type="button"
                                    info
                                    [style]="'small'"
                                    [icon]="'file-download'"
                                    custom-class="id-download-btn-logo"
                                    title="{{'GLOBAL.DOWNLOAD' | translate }}"
                                >
                                </button>
                            </a>
                        </div>
                        <!-- Faili digiallkirjastamise tegevusnupu ikoon kuvada juhul,
                    kui tabel kuvatakse MK keskkonnas
                    ja fail on olemas ja on kustutamata ja doc_document.signature_uuid on tühi -->
                        <div
                            class="ml-2"
                            *ngIf="env === 'mk' && doc.fileOriginName && !doc.deleted && !doc.signatureUuid"
                        >
                            <button
                                id="{{ 'signDocBtnId' + type + i }}"
                                light
                                [style]="'small'"
                                type="button"
                                custom-class="id-logo-sm-btn"
                                (click)="signDocument(doc)"
                                title="{{'GLOBAL.SIGN' | translate }}"
                                [icon]="''"
                            >
                                <span></span>
                            </button>
                        </div>
                        <div class="ml-2">
                            <button
                                *ngIf="!noDel && mode==='rw' && !doc.deleted
                            && ((env === 'ik' && doc.relCount === 0 && !doc.officialOnly) || (env === 'mk'))"
                                id="{{ 'deleteDocBtnId' + type + i }}"
                                type="button"
                                danger
                                [style]="'small'"
                                [icon]="'trashcan'"
                                title="{{'GLOBAL.DELETE' | translate }}"
                                (click)="confirmDelete(doc)"
                            >
                            </button>
                        </div>
                        <div
                            class="ml-2"
                            *ngIf="env === 'mk' && doc.deleted"
                        >
                            {{ 'GLOBAL.DELETED' | translate }}
                            <button
                                id="{{ 'restoreDocBtnId' + type + i }}"
                                type="button"
                                info
                                [style]="'small'"
                                [icon]="'square-pencil2'"
                                title="{{'GLOBAL.RESTORE' | translate }}"
                                (click)="onRestoreDoc(doc.id)"
                            >
                            </button>
                        </div>
                    </div><!-- END 'apldocs' actions -->
                </div>
            </td>
        </tr>
    </tbody>
</table>

<div [formGroup]="parentForm">
    <ng-template #signDocModal>
        <div class="modal-header pb-1">
            <h2 class="modalTitle mt-3">{{'AUTH.SIGNDOCUMENT'| translate}}</h2>
            <button
                light
                [style]="'round'"
                [icon]="'exit'"
                title="{{ 'GLOBAL.CLOSE' | translate }}"
                (click)="closeSignModal()"
            ></button>
        </div>
        <div class="modal-body py-1">
            <div class="py-3"></div>
            <div>
                <tabset>
                    <tab
                        heading="{{ 'AUTH.MOBILEID' | translate }}"
                        [active]
                        id="tab2"
                    >
                        <div class="form-group row py-3">
                            <label class="col-5 col-form-label">{{ 'USERDATA.IKOOD' | translate }}</label>
                            <div><input
                                    formControlName="code"
                                    class="form-control"
                                    id="code"
                                    type="text"
                                /></div>
                        </div>
                        <div class="form-group row">
                            <label class="col-5 col-form-label">{{ 'AUTH.PHONE' | translate }}</label>
                            <div><input
                                    formControlName="phone"
                                    class="form-control"
                                    id="phone"
                                    type="text"
                                /></div>
                        </div>
                    </tab>
                    <tab heading="{{ 'AUTH.IDCARD' | translate }}"></tab>
                    <tab heading="{{ 'AUTH.SMARTID' | translate }}"></tab>
                </tabset>
            </div>
            <hr />
            <div class="col-xs-20 pt-1 mb-3 d-flex justify-content-end">

                <div class="ml-2">
                    <button
                        id="signBtnId"
                        type="button"
                        success
                        [style]="'square'"
                        [icon]="'shield'"
                        (click)="onSignDoc()"
                    >
                        <span>{{'AUTH.SIGN' | translate }}</span>
                    </button>
                </div>
                <div class="ml-2">
                    <button
                        id="signCancelBtnId"
                        type="button"
                        danger
                        [style]="'square'"
                        [icon]="'cancel'"
                        (click)="signModalRef.hide()"
                        title="{{'GLOBAL.CLOSE' | translate }}"
                    >
                        <span>{{'GLOBAL.CLOSE' | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</div>


<ng-template #deleteDocModal>
    <div class="modal-header pb-1">
        <h4 class="modalTitle mt-3">{{'DOC.DEL_WARNING'| translate}}</h4>
        <button
            light
            [style]="'round'"
            [icon]="'exit'"
            title="{{ 'GLOBAL.CLOSE' | translate }}"
            (click)="closeModal()"
        ></button>
    </div>
    <div class="modal-body py-0">
        <div class="col-xs-20 pt-3 mb-3 d-flex justify-content-end">
            <div class="ml-2">
                <button
                    id="confirmBtnId"
                    type="button"
                    success
                    [style]="'square'"
                    [icon]="'save'"
                    (click)="onDelete()"
                    title="{{'GLOBAL.YES' | translate }}"
                >
                    <span>{{'GLOBAL.YES' | translate }}</span>
                </button>
            </div>
            <div class="ml-2">
                <button
                    id="cancelBtnId"
                    type="button"
                    danger
                    [style]="'square'"
                    [icon]="'cancel'"
                    (click)="bsConfirmRef.hide()"
                    title="{{'GLOBAL.NO' | translate }}"
                >
                    <span>{{'GLOBAL.NO' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>