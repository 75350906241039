<div *ngIf="!isSearching && searchResults && searchResults.length > 0">
    <div
        *ngFor="let item of searchResults; let infoIndex = index"
        [id]="'alert-'+ item.id"
        class="alert alert-primary"
        role="alert"
    >
        <div class="row m-0">
            <div class="container p-0 my-0 text-center">
                <strong>{{lang === 'et' ? item.subjectEst : item.subjectEng}}</strong>
                {{lang === 'et' ? item.messageEst : item.messageEng}}
            </div>
        </div>
    </div>
</div>