import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
// 3rd party modules
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxPaginationModule } from 'ngx-pagination';

import { BreadcrumbModule } from 'angular-crumbs';
// SKA directives (https://koodivaramu.eesti.ee/veebiraamistik/angular/tree/TEHIK/libs/common/src/lib/directives)
import { ButtonDirective, LinkDirective, AlphabetOnlyDirective } from './_directives';
// SKA pipes
import { SkaCurrencyPipe, SkaDatePipe, SkaDateTimePipe } from './_pipes';

// Our own components
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LogoComponent } from './header/logo/logo.component';
import { UserbarComponent } from './header/userbar/userbar.component';
import { MainnavComponent } from './header/mainnav/mainnav.component';
import { LangbarComponent } from './header/langbar/langbar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { LoadingSpinnerComponent } from './_common/loading-spinner/loading-spinner.component';
import { BadgeComponent } from './_common/badge/badge.component';
import { FormgroupRowComponent } from './_common/formgroup-row/formgroup-row.component';
import { NoResultsComponent } from './_common/no-results/no-results.component';
import { SortableColumnComponent } from './_common/sortable-column/sortable-column.component';
import { SortableTableDirective } from './_directives/sortable-table.directive';
import { UserChangeRoleComponent } from './header/userbar/user-change-role/user-change-role.component';
import { BaseComponent } from './_common/base/base.component';
import { PaginatorComponent } from './_common/paginator/paginator.component';

// Translation module
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IsothreePipe } from './_pipes/isothree.pipe';
import { NotificationComponent } from './_common/notification/notification.component';
import { DatepickerComponent } from './_common/datepicker/datepicker.component';
import { ValuesPipe } from './_pipes/map-values.pipe';
import { DocumentComponent } from './document/document.component';
import { DateTimePickerComponent } from './_common/date-time-picker/date-time-picker.component';
import { DocumentTableComponent } from './document-table/document-table.component';
// tslint:disable-next-line: import-spacing
// tslint:disable-next-line: max-line-length
import { InspectionDocsExtSelectComponent } from '../inspections/inspection-detail/inspection-docs-ext-select/inspection-docs-ext-select.component';
import { AddEditInspectionComponent } from '../inspections/add-edit-inspection/add-edit-inspection.component';
import { DocumentSimpleComponent } from './document-simple/document-simple.component';
import { IntegerInputDirective } from './_directives/integer-input.directive';
import { InputRegexValidatorDirective } from './_directives/input-regex-validator.directive';
import { SelectComponent } from './_common/select/select.component';
import { HttpConfigInterceptor } from '../interceptor/httpconfig.interceptor';
import { AddIsikComponent } from './_common/add-isik/add-isik.component';
import { FormgroupMultiRowComponent } from './_common/formgroup-multi-row/formgroup-multi-row.component';
import { DocViewComponent } from './_common/doc-view/doc-view.component';
import { SelectDocComponent } from './_common/select-doc/select-doc.component';
import { EditRecreatInspectionComponent } from '../inspections/edit-recreat-inspection/edit-recreat-inspection.component';
// export const options: Partial<IConfig> | (() => Partial<IConfig>);
import { UserNotificationsComponent } from './header/userbar/user-notifications/user-notifications.component';
import { NgxModalDraggableDirective } from './_directives/draggable.directive';
import { DisableControlDirective } from './_directives/disable-control.directive';
import { MainAlertComponent } from './_common/main-alert/main-alert.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    InputRegexValidatorDirective,
    IntegerInputDirective,
    ButtonDirective,
    DisableControlDirective,
    NgxModalDraggableDirective,
    LinkDirective,
    AlphabetOnlyDirective,
    SkaCurrencyPipe,
    SkaDatePipe,
    SkaDateTimePipe,
    HeaderComponent,
    FooterComponent,
    LogoComponent,
    UserbarComponent,
    MainnavComponent,
    LangbarComponent,
    BreadcrumbsComponent,
    LoadingSpinnerComponent,
    BadgeComponent,
    FormgroupRowComponent,
    NoResultsComponent,
    SortableColumnComponent,
    SortableTableDirective,
    UserChangeRoleComponent,
    BaseComponent,
    PaginatorComponent,
    IsothreePipe,
    NotificationComponent,
    DatepickerComponent,
    ValuesPipe,
    DocumentComponent,
    DateTimePickerComponent,
    DocumentTableComponent,
    InspectionDocsExtSelectComponent,
    AddEditInspectionComponent,
    DocumentSimpleComponent,
    SelectComponent,
    // HttpConfigInterceptor,
    AddIsikComponent,
    FormgroupMultiRowComponent,
    DocViewComponent,
    SelectDocComponent,
    EditRecreatInspectionComponent,
    UserNotificationsComponent,
    MainAlertComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    // PaginationModule.forRoot(),
    /* TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }), */
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    InputRegexValidatorDirective,
    IntegerInputDirective,
    ButtonDirective,
    DisableControlDirective,
    NgxModalDraggableDirective,
    LinkDirective,
    AlphabetOnlyDirective,
    SkaCurrencyPipe,
    SkaDatePipe,
    SkaDateTimePipe,
    HeaderComponent,
    FooterComponent,
    LogoComponent,
    UserbarComponent,
    MainnavComponent,
    LangbarComponent,
    BreadcrumbsComponent,
    LoadingSpinnerComponent,
    BadgeComponent,
    FormgroupRowComponent,
    TooltipModule,
    CollapseModule,
    TabsModule,
    PopoverModule,
    TypeaheadModule,
    FormsModule,
    ReactiveFormsModule,
    NoResultsComponent,
    SortableColumnComponent,
    SortableTableDirective,
    UserChangeRoleComponent,
    PaginatorComponent,
    NgxPaginationModule,
    IsothreePipe,
    DatepickerComponent,
    RouterModule,
    ValuesPipe,
    DocumentComponent,
    DateTimePickerComponent,
    InspectionDocsExtSelectComponent,
    AddEditInspectionComponent,
    DocumentSimpleComponent,
    DocumentTableComponent,
    SelectComponent,
    NotificationComponent,
    // HttpConfigInterceptor,
    AddIsikComponent,
    FormgroupMultiRowComponent,
    DocViewComponent,
    EditRecreatInspectionComponent,
    MainAlertComponent
  ],
  entryComponents: [
    DocumentComponent,
    InspectionDocsExtSelectComponent,
    DocumentSimpleComponent,
    DocumentTableComponent,
    SelectDocComponent
  ],
  providers: [IsothreePipe]
})

// NB! Don't forget to import the shared module for individual sub-modules off app-module that might need it!
export class SharedModule { }
