import { Component, ElementRef, NgZone, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import {
  ChildActivationStart,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
  RoutesRecognized
} from '@angular/router';
import { Subscription } from 'rxjs';

import { API_ENDPOINT, DEBUG_MODE, LANGUAGE_OPTIONS, LANGUAGE_OPTIONS_DEFAULT } from './app.constants';

import { ClassificatorObjects, UserInfo } from './_domain';
import { ClassifierService } from './shared/_services';
import { UserChangeRoleComponent } from './shared/header/userbar/user-change-role/user-change-role.component';

/*
  Idle Timeout example: https://blog.bitsrc.io/how-to-implement-idle-timeout-in-angular-af61eefdb13b
*/
import {
  DocumentInterruptSource,
  EventTargetInterruptOptions,
  Idle,
  StorageInterruptSource
} from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { filter } from "rxjs/operators";
import { GlobalConstants } from 'src/app/common/global-constants';
import { HomeComponent } from './home/home.component';
import { BaseComponent } from './shared/_common/base/base.component';
import { UserbarComponent } from './shared/header/userbar/userbar.component';
declare var $: any;

// Defineeritakse mis eventide peale time out cout lõppeb
export function createDefaultInterruptSources(options?: EventTargetInterruptOptions) {
  return [new DocumentInterruptSource(
    // mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll
    'click touchstart touchmove', options),
  new StorageInterruptSource()];
}

export const CUSTOM_EVENT_INTERRUPTSOURCES: any[] = createDefaultInterruptSources();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  isAppRootVisible = false;
  showHomePage = false;
  DOC_TITLE = GlobalConstants.DOC_TITLE;
  /*
      Subscriptions
    */
  public subscriptions: Subscription[] = [];

  debugMode: boolean = DEBUG_MODE;
  lang: string;
  currentUser: UserInfo;

  modalRef: BsModalRef;
  @ViewChild('confirmTimeoutTmp') confirmTimeoutTmp: TemplateRef<any>;
  @ViewChild('timeoutMessageTmp') timeoutMessageTmp: TemplateRef<any>;
  @ViewChild('#timerId') div: ElementRef;
  // stop modal backdrop
  modalNoBackdropConfig = {
    backdrop: false,
    ignoreBackdropClick: true
  };

  userBarC: UserbarComponent;

  // IDLE
  @ViewChild('timer')
  public timer: ElementRef;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';

  globalsSessionTimeoutInterval = 12 * 60; // 12 millal hoiatamise modal näidatakse
  globalsTimeoutWarningTime = 3 * 60; // hoiatamine kestab
  countdownInSeconds = 0;
  duration: any;
  countdownTime: any;

  isTermsAcceped = false;
  isApplicationTermsAccepted = false;
  isUserTermsAccepted = false;
  isRoleSelected = false;

  constructor(
    private router: Router,
    private classifierService: ClassifierService,
    private idle: Idle,
    private keepalive: Keepalive,
    private zone: NgZone,
    private renderer: Renderer2
  ) {
    super();
    this.translateServ.addLangs(LANGUAGE_OPTIONS);
    this.translateServ.setDefaultLang(LANGUAGE_OPTIONS_DEFAULT);

    this.initIdleTimeout();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      window['dataLayer'].push({
        event: 'page_view', // Kui event ei kajastu GTM konto all siis peab ilmselt seal looma uue "Custom Event" Triggeri "page_view"
        page: event.urlAfterRedirects
      })
    })

    this.addSubscriptionToList(this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.addSpinnerLayer();
          // console.log('NavigationStart spinner');
          break;
        }
        case event instanceof ChildActivationStart: {
          this.addSpinnerLayer();
          // console.log('ChildActivationStart spinner');
          break;
        }
        case event instanceof NavigationEnd: {
          this.removeSpinnerLayer();
          // console.log('NavigationEnd spinner');
          break;
        }
        case event instanceof NavigationCancel: {
          this.removeSpinnerLayer();
          // console.log('NavigationCancel spinner');
          break;
        }
        default: {
          // console.log('default event spinner ', event);
          break;
        }
      }
    }));
  }

  ngOnInit() {
    // this.setBodyWhite();
    this.currentUser = this.userService.getUser();
    const userC = new UserChangeRoleComponent(this.router);
    if (this.currentUser === null) {
      this.addSpinnerLayer();
      this.addSubscriptionToList(
        this.userService.loadUser().subscribe(
          userInfo => {
            if (userInfo === undefined || userInfo === null) {
              this.removeSpinnerLayer();
              console.log("app component goToLogin call in init");
              this.goToLogin();
            } else {
              this.removeSpinnerLayer();
              userInfo.rolesSize = Object.keys(userInfo.roles).length;
              userInfo.entitiesSize = Object.keys(userInfo.entities).length;
              userInfo.entities = this.userService.setUserRealEntityFirstInMap(userInfo);
              if (userInfo.timeoutMinutes) {
                this.globalsSessionTimeoutInterval = +userInfo.timeoutMinutes * 60;
              }
              //  console.log('app.component');
              this.currentUser = userInfo;
              if (this.currentUser) {
                this.userService.userSubject.next(userInfo);
              }
              this.isTermsAcceped = (this.currentUser.sysConditions !== null && this.currentUser.personalData !== null) ||
                localStorage.getItem('terms') === this.currentUser.autPersonId.toString();
              if (!this.isTermsAcceped) {
                sessionStorage.removeItem('currentActiveRoleEnvinronment');
                sessionStorage.removeItem('currentActiveEnvinronment');
              }
              if (
                this.currentUser !== undefined &&
                this.currentUser !== null &&
                this.currentUser.entities !== undefined &&
                Object.keys(this.currentUser.entities).length === 1 &&
                this.currentUser.roles !== undefined &&
                this.currentUser.roles !== null &&
                Object.keys(this.currentUser.roles).length === 0
              ) {
                this.userService.userSubject.next(userInfo);
              }
            }
            // this.removeBodyWhite();
            this.setBodyLightGray();
            this.isAppRootVisible = true;
          },
          error => {
            // "Unauthorized"
            if (error.status === 401) {
              console.log("app component goToLogin call in error");
              this.goToLogin();
            } else {
              this.getLongMsgError(error.message);
            }
            this.removeSpinnerLayer();
            /* this.removeBodyWhite(); */
            this.setBodyLightGray();
            this.isAppRootVisible = true;
          }
        )
      );
    } else {
      this.setBodyLightGray();
      if (
        this.currentUser !== undefined &&
        this.currentUser !== null &&
        this.currentUser.entities !== undefined &&
        Object.keys(this.currentUser.entities).length === 1 &&
        this.currentUser.roles !== undefined &&
        this.currentUser.roles !== null &&
        Object.keys(this.currentUser.roles).length === 0
      ) {
        this.userService.userSubject.next(this.currentUser);
      }
      this.isTermsAcceped = (this.currentUser.sysConditions !== null && this.currentUser.personalData !== null) ||
        localStorage.getItem('terms') === this.currentUser.autPersonId.toString();
      if (!this.isTermsAcceped) {
        sessionStorage.removeItem('currentActiveRoleEnvinronment');
        sessionStorage.removeItem('currentActiveEnvinronment');
      }
      this.isAppRootVisible = true;
    }

    /*
      classifier ListItem to session
    */
    this.addSubscriptionToList(
      this.classifierService.getClassifiers().subscribe(
        // TODO: kui pannakse sobivasse ümbrikusse siis muuta see ära
        (data: ClassificatorObjects) => {
          if (data === undefined || data === null || data.length === 0) {
            this.addSubscriptionToList(this.translateServ.get('ERROR.CL_LISTITEM.EMPTY').subscribe(label => {
              this.getLongMsgError(label);
            }));
          }
        },
        error => {
          this.addSubscriptionToList(this.translateServ.get('ERROR.CL_LISTITEM.EMPTYER').subscribe(label => {
            const errorHtml = '<div>' + label + '<br/><br/> ' + error + ' </div>';
            if (this.isAppRootVisible) {
              this.getLongMsgError(label);
            }
          }));
        }
      )
    );

    this.addSubscriptionToList(this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        const routeLang = val.state.root.firstChild.params.lang;
        this.langswitchService.updateCurrentLang(routeLang);
        this.translateServ.use(routeLang);
      }
    }));


    this.userBarC = new UserbarComponent(this.router);

    this.lang = this.translateServ.currentLang;
    this.addSubscriptionToList(this.langswitchService.routeLanguage.subscribe(
      clang => {
        this.lang = clang;
      }
    ));

    const cEnv = this.userService.getUserSelectedRoleEnvinronment();
    const mainRouterOutletCls = window.document.getElementsByClassName('mainRouterOutletCls');
    if (cEnv) {
      this.isRoleSelected = true;
      if (mainRouterOutletCls && mainRouterOutletCls[0]) {
        mainRouterOutletCls[0].classList.add('bg-white');
        mainRouterOutletCls[0].classList.remove('bg-gray-light');
      }

    }
    this.addSubscriptionToList(this.userService.getUserEnvinronmentSubject.subscribe(env => {
      if (env) {
        this.isRoleSelected = true;
        if (mainRouterOutletCls && mainRouterOutletCls[0]) {
          mainRouterOutletCls[0].classList.add('bg-white');
          mainRouterOutletCls[0].classList.remove('bg-gray-light');
        }
      }
    }));
  }

  public onRouterOutletActivate(event: any) {
    const mainRouterOutletCls = window.document.getElementsByClassName('mainRouterOutletCls');
    if (event === HomeComponent) {
      this.setBodyLightGray();
      this.isRoleSelected = false;
      if (mainRouterOutletCls && mainRouterOutletCls[0]) {
        mainRouterOutletCls[0].classList.add('bg-gray-light');
        mainRouterOutletCls[0].classList.remove('bg-white');
      }
    } else {
      this.isRoleSelected = true;
      if (mainRouterOutletCls && mainRouterOutletCls[0]) {
        mainRouterOutletCls[0].classList.add('bg-white');
        mainRouterOutletCls[0].classList.remove('bg-gray-light');
      }
    }
  }

  async confirmTerms() {
    sessionStorage.removeItem('currentActiveRoleEnvinronment');
    sessionStorage.removeItem('currentActiveEnvinronment');
    this.addSpinnerLayer();

    this.subscriptions.push(
      this.userService.acceptSysTerms(this.isApplicationTermsAccepted, this.isUserTermsAccepted).subscribe(
        async data => {
          this.isTermsAcceped = true;
          this.isAppRootVisible = true;
          this.removeSpinnerLayer();

          this.subscriptions.push(
            this.userService.loadUser().subscribe(
              async (userInfo: UserInfo) => {

                localStorage.setItem('terms', userInfo.autPersonId.toString());
                this.isTermsAcceped = (this.currentUser.sysConditions !== null && this.currentUser.personalData !== null) ||
                  localStorage.getItem('terms') === userInfo.autPersonId.toString();

                userInfo.rolesSize = Object.keys(userInfo.roles).length;
                userInfo.entitiesSize = Object.keys(userInfo.entities).length;
                userInfo.entities = this.userService.setUserRealEntityFirstInMap(userInfo);

                this.currentUser = userInfo;
                this.userService.getActiveRoleTitle(userInfo);

                this.userService.userSubject.next(userInfo);
              },
              error => {
                if (error.message) {
                  this.getLongMsgError(error.message);
                  sessionStorage.removeItem('currentActiveRoleEnvinronment');
                  sessionStorage.removeItem('currentActiveEnvinronment');
                }
              }
            )
          );

        },
        error => {
          if (error.status === 401) {
            this.userBarC.logout();
          }
        })
    );
  }



  /*
    Refresh backend session
  */
  async getUser() {
    try {
      const userInfo = await this.userService.loadUser().toPromise();

      if (userInfo === undefined || userInfo === null) {
        this.userBarC.logout();
        this.showHomePage = true
      } else {
        userInfo.rolesSize = Object.keys(userInfo.roles).length;
        userInfo.entitiesSize = Object.keys(userInfo.entities).length;
        userInfo.entities = this.userService.setUserRealEntityFirstInMap(userInfo);
        if (userInfo.timeoutMinutes) {
          this.globalsSessionTimeoutInterval = (+userInfo.timeoutMinutes - 3) * 60;
        }
        this.currentUser = userInfo;
        this.userService.setUser(userInfo);
        if (this.currentUser) {
          this.userService.userSubject.next();
        }
      }
    } catch (error) {
      // "Unauthorized"
      if (error.status === 401) {
        this.userBarC.logout();
        this.showHomePage = true
      } else {
        this.getLongMsgError(error.message);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      if (subscription !== undefined) {
        subscription.unsubscribe();
      }
    });
    this.unsubscribeAllFromList();
  }

  goToLogin(): void {
    console.log("app component goToLogin");

    this.showHomePage = true
    window.location.href = API_ENDPOINT + '/' + this.lang;
  }

  /*
    MODAL
  */
  openIdleConfirmModal() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    window.document.body.classList.add('rais-modal');
    this.modalRef = this.modalService.show(this.confirmTimeoutTmp, Object.assign({},
      { class: 'modal-sm' }, this.modalNoBackdropConfig));
  }

  openSessionEndModal() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    window.document.body.classList.add('rais-modal');
    this.modalRef = this.modalService.show(this.timeoutMessageTmp, Object.assign({},
      { class: 'modal-sm' }, this.modalNoBackdropConfig));
  }

  closeModal(): void {
    this.modalRef.hide();
    window.document.body.classList.remove('rais-modal');
  }


  closePopover(): void {
    const popover = document.querySelector('popover-container');
    // TODO button click bug!
    if (popover !== null) {
      popover.classList.add('d-none');
    }
  }

  /*
    IDLE

    FOR test
    DOTO: need to modify to our project!
  */
  initIdleTimeout() {
    // sets an idle timeout of 5 seconds, for testing purposes.
    // aeg peale mdia näidatakse hoiatus teadet (hetkel 12 min)
    this.idle.setIdle(this.globalsSessionTimeoutInterval); // show warning
    // hoiatus teate näitamine (kui kaua kestab hoiatus teade et sessioon hakkab aeguma) (hetkel 3 min)
    this.idle.setTimeout(this.globalsTimeoutWarningTime); // this.globalsTimeoutWarningTime // when logout
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(CUSTOM_EVENT_INTERRUPTSOURCES); // DEFAULT_INTERRUPTSOURCES CUSTOM_EVENT_INTERRUPTSOURCES

    // Refresed
    this.addSubscriptionToList(this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';

      // reset timer value
      this.countdownTime = '';
    }));

    this.addSubscriptionToList((this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;

      this.closeModal();
      this.closePopover();
      this.openSessionEndModal();
    })));

    // SHOW WARNING
    this.addSubscriptionToList((this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      // console.log(this.idleState);


      // TODO: Close other modals
      if (!this.isAppRootVisible) {
        return
      }

      this.openIdleConfirmModal();
      this.closePopover();
      if (!document.hasFocus) {

      }
    })));
    // timeout count
    this.addSubscriptionToList(this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.countdownInSeconds = countdown;

      this.duration = moment.duration(this.countdownInSeconds - 1, 'seconds');
      this.countdownTime = moment.utc(this.duration.asMilliseconds()).format('mm:ss');
    }));

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);
    this.addSubscriptionToList(this.keepalive.onPing.subscribe(() => this.lastPing = new Date()));

    this.zone.runOutsideAngular(() => {
      setInterval(() => {
        $(() => {
          // #timerId is inside modal
          const timerEl = $('#timerId');
          if (timerEl && timerEl[0]) {
            // Change document title
            document.title = (this.countdownTime ? this.countdownTime + ' ' : '') + this.DOC_TITLE;
            this.renderer.setProperty(timerEl[0], 'textContent', this.countdownTime);
          }
        });
      }, 333);
    });

    this.resetIdle(false);
  }

  resetIdle(showSuccessMessage = true) {
    if (!this.timedOut) {
      // this.console('reset', this.timedOut);
      this.countdownTime = undefined;
      this.idle.watch();
      this.idleState = 'Started.';
      this.timedOut = false;
      // Refresh session
      this.getUser();
      document.title = this.DOC_TITLE;
    }
  }


  logout() {
    this.userBarC.logout();
  }

  /*
    SPINNER
  */
  addSpinnerLayer() {
    window.document.body.classList.add('show-spinner-layer');
    /*  console.log('addSpinnerLayer', window.document.body.classList); */
  }

  removeSpinnerLayer() {
    window.document.body.classList.remove('show-spinner-layer');
    setTimeout(() => {
      if (!this.currentUser) {
        this.showHomePage = true
      }
    }, 1000);

    /* console.log('removeSpinnerLayer', window.document.body.classList); */
  }

  setBodyWhite() {
    window.document.body.classList.add('bg-white');
  }

  setBodyLightGray() {
    window.document.body.classList.add('bg-gray-light');
  }

  removeBodyWhite() {
    window.document.body.classList.remove('bg-white');
  }

  show(val) {
    console.log('app ', val);
    return val;
  }
}
