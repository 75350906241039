import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AdminNotificationsData } from "src/app/_domain/admin-notifications.interface";
import { API_ENDPOINT } from "src/app/app.constants";

@Injectable({
    providedIn: 'root'
})
export class AdmNotificationService {

    constructor(private http: HttpClient) { }

    saveNewNotification(notification: AdminNotificationsData): Observable<any> {
        const saveDataProps = ['subjectEst', 'messageEst', 'subjectEng', 'messageEng', 'active'];
        const newAdminNotification = {};
        saveDataProps.forEach((propName: string) => {
            if (notification[propName] !== null) {
                newAdminNotification[propName] = notification[propName];
            }
        });

        return this.http
            .post<any>(API_ENDPOINT + 'api/admin/notifications', newAdminNotification)
            .pipe(map((response: any) => response));
    }

    deleteNotification(notificationId: number): any { // TODO: PROPER RETURN TYPE
        return this.http
            .delete<any>(API_ENDPOINT + 'api/admin/notifications/' + notificationId)
            .pipe(map((response: any) => response));
    }

    getAllNotifications(): Observable<any> {
        return this.http
            .get<any>(API_ENDPOINT + 'api/admin/notifications/all')
            .pipe(map((response: any) => response));
    }

    getNotification(notificationId: number): Observable<any> {
        return this.http
            .get<any>(API_ENDPOINT + 'api/admin/notifications/' + notificationId)
            .pipe(map((response: any) => response));
    }

    editNotification(notification: AdminNotificationsData): Observable<any> {
        return this.http
            .put<any>(API_ENDPOINT + 'api/admin/notifications', notification)
            .pipe(map((response: any) => response));
    }

    getActiveNotifications(): Observable<any> {
        return this.http
            .get<any>(API_ENDPOINT + 'api/admin/notifications/active')
            .pipe(map((response: any) => response));
    }
}
