import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Global shared module
import { SharedModule } from '../shared/shared.module';

import { HomePageComponent } from './home-page.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HomePageComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule
  ],
  exports: [HomePageComponent],
})
export class HomePageModule { }
