import { Component, OnInit } from '@angular/core';
import { BaseComponent } from "../base/base.component";
import { AdmNotificationService } from "../../_services/adm-notification.service";
import { AdminNotificationsData } from "src/app/_domain/admin-notifications.interface";

declare var $: any;

@Component({
  selector: 'app-main-alert',
  templateUrl: './main-alert.component.html',
  styleUrls: ['./main-alert.component.scss']
})
export class MainAlertComponent extends BaseComponent implements OnInit {
  searchResults: AdminNotificationsData[] = null;
  isSearching: boolean;
  lang: string;

  constructor(private admNotificationService: AdmNotificationService) {
    super();
  }

  ngOnInit(): void {
    this.lang = this.translateServ.currentLang;
    this.addSubscriptionToList(this.langswitchService.routeLanguage.subscribe(
      clang => {
        this.lang = clang;
      }
    ));
    this.isSearching = true
    this.addSubscriptionToList(
      this.admNotificationService.getActiveNotifications().subscribe(
        (result: any) => {
          console.log("RESULT NOTIFI ", result);

          if (result.data.length > 0) {
            this.searchResults = result.data
          }
          this.isSearching = false
        },
        error => {
          this.isSearching = false
        })
    );
  }
}
