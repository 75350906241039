import { Component, OnInit } from '@angular/core';
import { API_ENDPOINT } from "../app.constants";
import { BaseComponent } from "../shared/_common/base/base.component";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
    // this.removeSpinnerLayer()
  }

  goToLogin(): void {
    window.location.href = API_ENDPOINT + 'app/login';
  }
}
